<template>
  <Modal :addClass="'modal-livetv modal-group-tv'" :showLoader="showLoader">
    <ModalHeaderTv
      v-if="canPlay"
      :asset="asset"
      :live="selectedSeasonFirstChapterStatus === 'PRESENTE'"
      :playTitle="
        selectedSeasonFirstChapterStatus == 'Planned'
          ? 'Programada'
          : 'Reproducir'
      "
      @play="onPlay(selectedSeasonFirstChapter)"
    />
    <ModalHeaderTv v-else :asset="asset" />

    <div class="modal-bottom fade" :class="{ show: showBottom }">
      <div v-if="selectedSeason" class="modal-bottom-controls">
        <h6>Episodios de la</h6>
        <SelectModal
          :title="selectedSeason.name"
          :list="asset.seasons"
          textField="name"
          @select="onSelectSeason(arguments[1])"
        />
      </div>

      <div class="seasons-wrapper fade show" v-if="showBottom">
        <ol
          v-if="selectedSeason"
          class="list-group list-group-flush list-seasons"
        >
          <template
            v-if="isChannelListed && cap.status !== 'Planned'"
            v-for="(cap, indexChapter) in selectedSeason.capitulos"
          >
            <ChapterDetail
              :ref="'episodio-' + cap.idAsset"
              :key="cap.idAsset"
              :data="cap"
              :title="cap.subtitle"
              :isPlaying="isPlaying(cap.idAsset)"
              @play="onPlay(cap)"
              @pause="onPause(cap)"
            />
          </template>
          <template
            v-else
            v-for="(cap, indexChapter) in selectedSeason.capitulos"
          >
            <ChapterDetail
              :ref="'episodio-' + cap.idAsset"
              :key="cap.idAsset"
              :data="cap"
              :title="cap.subtitle"
            />
          </template>
        </ol>

        <section v-else class="empty-state">
          <figure>
            <img
              src="../assets/images/empty-illustration.svg"
              width="350"
              height="211"
              alt="Empty Illustration"
            />
          </figure>

          <h1 v-if="asset.seasons" class="h1">
            La temporada no tiene episodios disponibles.
          </h1>
          <h1 v-else class="h1">La serie no tiene contenido disponible.</h1>
        </section>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "ModalGroupTv",

  components: {
    Modal: () => import("@/components/Modal.vue"),
    ModalHeaderTv: () => import("@/components/ModalHeaderTv.vue"),
    SelectModal: () => import("@/components/SelectModal.vue"),
    ChapterDetail: () => import("@/components/ChapterDetail.vue"),
  },

  data() {
    return {
      asset: {
        seasons: [],
      },
      indexSeason: 0,
      showBottom: false,
      showLoader: true,
    };
  },

  computed: {
    selectedSeason() {
      return this.asset && this.asset.seasons
        ? this.asset.seasons[this.indexSeason] || null
        : null;
    },

    selectedSeasonFirstChapter() {
      return this.selectedSeason && this.selectedSeason.capitulos
        ? this.selectedSeason.capitulos[0] || null
        : null;
    },

    selectedSeasonFirstChapterStatus() {
      return this.selectedSeasonFirstChapter
        ? this.selectedSeasonFirstChapter.status || ""
        : "";
    },

    isChannelListed() {
      return this.asset.channel && this.asset.channel.listed;
    },

    canPlay() {
      var statusOk =
        ["FUTURO", "Planned", ""].indexOf(
          this.selectedSeasonFirstChapterStatus
        ) === -1;

      return this.isChannelListed && statusOk;
    },
  },

  mounted() {
    this.$bus.$on("modal-group-tv:show", this.show);
  },

  beforeDestroy() {
    this.$bus.$off("modal-group-tv:show", this.show);
  },

  methods: {
    show(obj) {
      const self = this;

      tplay.resetVueData(self);

      //Empiezo a cargar el contenido inmediatamente, mientras en paralelo abro el modal
      telecentro.tplay.core.helpers.join(
        __.partial(showModal, ".modal-group-tv"),
        __.partial(self.loadDetail, obj.data)
      )(function () {
        self.showDetail();
      });
    },

    loadDetail(asset, done) {
      const self = this;

      if (asset.channel.dvbTriplet && asset.idAsset) {
        telecentro.tplay.core.epg.obtenerPrograma(
          asset.channel.dvbTriplet,
          asset.idAsset,
          function (programa) {
            Object.assign(self.asset, programa);
            done(programa);
          }
        );
      } else {
        Object.assign(self.asset, asset);
        done(asset);
      }
    },

    showDetail() {
      const self = this;

      if (gtag) {
        gtag("event", "Detalle grupo TV", {
          event_category: "ModalGroupTv",
          event_action: "Detalle grupo TV",
          event_label: self.asset.title || self.asset.name,
          value: "0",
        });
      }

      self.asset.title = telecentro.tplay.core.helpers.string.replaceUnicode(
        self.asset.title
      );
      self.asset.synopsis = telecentro.tplay.core.helpers.string.replaceUnicode(
        self.asset.synopsis
      );

      self.showLoader = false;

      //Espero a que se aplique el showLoader
      setTimeout(function () {
        self.showBottom = true;
      }, 100);
    },

    onSelectSeason(indexSeason) {
      this.indexSeason = indexSeason;
    },

    isPlaying(idAsset) {},

    onPlay(capitulo) {
      if (!capitulo) return;

      const labelChannel =
        capitulo.channel && capitulo.channel.name
          ? "[ " + capitulo.channel.name + " ] "
          : "";

      if (gtag) {
        gtag("event", "Reproducir desde modal", {
          event_category: "ModalGroupTv",
          event_action: "Reproducir desde modal",
          event_label: labelChannel + capitulo.name,
          value: "0",
        });
      }

      this.$bus.$emit("player:set-source", capitulo);
    },

    onPause(capitulo) {},
  },
};
</script>
